import linkedinLogo from './statics/linkedin.svg';
import githubLogo from './statics/github.svg';
import itchioLogo from './statics/itchio.svg';
import artstationLogo from './statics/artstation.svg';
import minime from './statics/minime.png';
import Link from './components/Link';
import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src={minime} width="200px" alt="Pixel art of eduardo costa" />
          <h1>Eduardo Costa</h1>
          <section className="Link-section">
            <Link 
              href="https://www.linkedin.com/in/eduhcosta/" 
              logo={linkedinLogo} 
              accessibilityLabel="Linkedin profile" 
            />
            <Link 
              href="https://github.com/eduhCosta" 
              logo={githubLogo} 
              accessibilityLabel="Github page" 
            />
            <Link 
              href="https://eduhcosta.itch.io" 
              logo={itchioLogo} 
              accessibilityLabel="Itch.io profile" 
            />
            <Link 
              href="https://www.artstation.com/eduardocosta" 
              logo={artstationLogo} 
              accessibilityLabel="Artstation profile" 
            />
          </section>
        </header>
      </div>

  );
}

export default App;
