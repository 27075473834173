/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */

const initialState = {
  code: '',
  gameData: [],
  errors: '',
  statusCode: '',
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_GAME_DATA':
      state = Object.assign({}, state, {
        code: 'itch.get.game.data.success',
        gameData: action.payload.data,
        statusCode: action.payload.code,
      });
      break;
    case 'GET_GAME_DATA_ERROR':
      state = Object.assign({}, state, {
        code: 'itch.get.game.data.error',
        error: action.payload.data,
        statusCode: action.payload.code,
      });
      break;
  }

  return state;
};