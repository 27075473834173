import './Link.css';

const Link = ({ href, logo, accessibilityLabel }) => {
    return (
        <a className='link' href={href} target="_blank" rel="noreferrer">
            <img 
                className='link--badge' 
                src={logo} 
                alt={accessibilityLabel} 
            />
        </a>
    )
}

export default Link;